import {useNavigate, useParams, useSearchParams} from "react-router-dom"
import {useEffect, useState} from "react"
import axiosService from "../service"
import OtpInput from 'react-otp-input'
import "./styles.css"

const useCountdown = (seconds: number) => {
    const [time, setTime] = useState(seconds)

    useEffect(() => {
        if (time > 0) {
            const timer = setInterval(() => setTime(time - 1), 1000)
            return () => clearInterval(timer)
        }
    }, [time])

    return {time, setTime}
}

export {useCountdown}


function DocumentSign() {
    const [otp, setOtp] = useState<string>('')
    const [error, setError] = useState(false)
    const {code} = useParams<{code: string}>()
    const {time} = useCountdown(59)
    const [searchParams, _] = useSearchParams()
    const confirm_id = searchParams.get('id')
    const navigate = useNavigate()

    const handleSubmit = () => {
        if (!confirm_id) return
        axiosService.signDocument(code!, {confirm_id, code: otp})
            .then(() => navigate(`/${code}/success`))
            .catch(() => setError(true))
    }

    return <div className="max-w-96 mx-auto flex flex-col gap-4">
        <div className="flex gap-4 items-center mb-4 self-start md:self-center">
            <svg className="cursor-pointer" onClick={() => navigate(`/${code}`)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.825 13.5L12.725 18.4C12.925 18.6 13.021 18.8334 13.013 19.1C13.005 19.3667 12.9007 19.6 12.7 19.8C12.5 19.9834 12.2667 20.0794 12 20.088C11.7333 20.0967 11.5 20.0007 11.3 19.8L4.7 13.2C4.6 13.1 4.529 12.9917 4.487 12.875C4.445 12.7584 4.42466 12.6334 4.426 12.5C4.42733 12.3667 4.44833 12.2417 4.489 12.125C4.52966 12.0084 4.60033 11.9 4.701 11.8L11.301 5.20005C11.4843 5.01672 11.7137 4.92505 11.989 4.92505C12.2643 4.92505 12.5017 5.01672 12.701 5.20005C12.901 5.40005 13.001 5.63772 13.001 5.91305C13.001 6.18838 12.901 6.42572 12.701 6.62505L7.825 11.5H19C19.2833 11.5 19.521 11.596 19.713 11.788C19.905 11.98 20.0007 12.2174 20 12.5C19.9993 12.7827 19.9033 13.0204 19.712 13.213C19.5207 13.4057 19.2833 13.5014 19 13.5H7.825Z" fill="black" />
            </svg>
            <h3 className="text-2xl font-bold">Введите код из СМС</h3>
        </div>

        <OtpInput
            shouldAutoFocus
            inputType="number"
            value={otp}
            onChange={setOtp}
            numInputs={4}
            containerStyle={{display: 'flex', gap: 12}}
            inputStyle={{width: '100%'}}
            renderInput={(props) => (
                <input
                    {...props}
                    type="text"
                    maxLength={1}
                    pattern="\d*"
                    className="w-full border border-solid border-[#DADCE0] focus:border-[#d2d4d8] rounded-xl px-2 py-8 text-center text-[32px] leading-[32px] outline-none"
                />
            )}
        />

        {error && <span className="mx-auto text-red-500">Ошибка</span>}

        <button disabled={otp.length !== 4} onClick={handleSubmit} className="bg-[#A165FD] text-white font-medium py-4 px-4 rounded-lg hover:bg-[#985fef] w-full md:w-80 self-center transition-all disabled:opacity-50">
            Подтвердить и подписать
        </button>
        <button disabled={time > 0} className="bg-[#F8F9FD] disabled:opacity-50 text-[#021221] font-medium py-4 px-4 rounded-lg hover:bg-[#f2f4f8] w-full md:w-80 self-center transition-all">
            Отправить код повторно 00:{time}
        </button>
    </div>
}

export default DocumentSign