import {useNavigate, useOutletContext, useParams} from "react-router-dom"
import "./styles.css"
import axiosService from "../service"

function Document() {
    const {code} = useParams<{code: string}>()
    const {document} = useOutletContext<{document: any}>()
    const navigate = useNavigate()

    const onSign = async () => axiosService.requestOTP(code!).then(({confirm_id}) => navigate(`/${code}/confirm?id=${confirm_id}`))

    return <>
        {document ? (
            <div
                className="flex-grow mx-auto ck-content"
                dangerouslySetInnerHTML={{__html: document?.content || ''}}
            />
        ) : (
            <div className="m-auto">Not found</div>
        )}

        <div className="fixed z-50 bottom-0 left-0 right-0 shadow-lg drop-shadow shadow-gray-100 bg-white p-3 text-center">
            <button
                onClick={onSign}
                className="bg-[#A165FD] text-white font-medium py-3 px-4 rounded-lg hover:bg-[#985fef] w-full md:w-max"
            >
                Отправить СМС-код и подписать
            </button>
        </div>
    </>
}

export default Document