export function Header() {
    return <header className="fixed z-50 left-0 right-0 top-0 bg-white min-h-[60px] max-h-[60px] lg:min-h-[80px] lg:max-h-[80px] px-5 lg:px-20 shadow-lg shadow-gray-100 flex justify-between items-center">
        <svg width="193" height="32" viewBox="0 0 193 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-[144px] lg:w-[192px]">
            <path d="M115.475 25.7432C110.008 25.7432 105.606 21.4431 105.606 16.0759C105.606 10.6425 109.874 6.40869 115.339 6.40869C119.541 6.40869 123.208 8.77573 124.641 13.0095H119.65C118.751 11.3078 117.351 10.4757 115.35 10.4757C112.287 10.4757 110.074 12.8087 110.074 16.0436C110.074 19.2785 112.375 21.6762 115.441 21.6762C117.276 21.6762 118.542 20.9428 119.641 19.2428H124.642C123.109 23.3762 119.776 25.7432 115.475 25.7432Z" fill="#A165FD" />
            <path d="M126.993 25.6629V0.074707H131.6V25.6629H126.993Z" fill="#A165FD" />
            <path d="M142.966 25.7246C137.577 25.7246 133.185 21.4006 133.185 16.076C133.185 10.7872 137.577 6.42749 142.934 6.42749C148.49 6.42749 152.782 10.7191 152.782 16.2088C152.775 21.4091 148.316 25.7246 142.966 25.7246ZM142.966 10.4877C140.073 10.4877 137.643 12.9824 137.643 16.0097C137.643 19.1714 140.005 21.6661 143 21.6661C145.927 21.6661 148.323 19.1714 148.323 16.076C148.316 13.0164 145.92 10.4877 142.966 10.4877Z" fill="#A165FD" />
            <path d="M167.148 25.6631V23.3096C165.416 25.0113 163.961 25.595 161.64 25.595C157.173 25.595 154.333 22.9982 154.333 16.2357V6.4834H158.939V15.3441C158.939 20.4355 160.359 21.3697 162.576 21.3697C164.135 21.3697 165.38 20.7112 166.075 19.6034C166.594 18.7372 166.801 17.5614 166.801 14.9969V6.4885H171.408V25.6631H167.148Z" fill="#A165FD" />
            <path d="M188.065 25.6631V22.7566C186.331 24.7203 184.533 25.5372 182.079 25.5372C176.941 25.5372 172.983 21.3833 172.983 16.0179C172.983 10.5862 176.974 6.33196 182.045 6.33196C184.335 6.33196 186.43 7.18281 187.706 8.5901V0H192.325V25.6631H188.065ZM182.668 10.3173C179.69 10.3173 177.367 12.7388 177.367 15.8137C177.367 19.0197 179.722 21.5399 182.765 21.5399C185.71 21.5399 188.04 19.0861 188.04 15.9448C188.033 12.7779 185.71 10.3241 182.668 10.3241V10.3173Z" fill="#A165FD" />
            <path d="M8.38249 32H3.42889L7.03135 23.7894L0 6.40503H5.26501L9.51921 18.8392L14.1614 6.40503H19.4604L8.38249 32Z" fill="#021221" />
            <path d="M34.6365 25.5932V23.3078C32.9042 25.0095 31.4492 25.5932 29.1281 25.5932C24.6612 25.5932 21.8211 22.9964 21.8211 16.2339V6.40503H26.4276V15.3406C26.4276 20.432 27.8468 21.3679 30.0641 21.3679C31.6228 21.3679 32.8684 20.7094 33.5627 19.6016C34.0732 18.7354 34.2893 18.0088 34.2893 15.4461V6.40503H38.8958V25.5932H34.6365Z" fill="#021221" />
            <path d="M66.2194 25.5932V15.5141C66.2194 11.67 64.7644 10.6303 62.6169 10.6303C60.0542 10.6303 58.7388 12.0172 58.7388 16.5862V25.5932H54.1374V15.5141C54.1374 11.7398 52.8203 10.6303 50.3937 10.6303C47.7272 10.6303 46.65 12.0512 46.65 16.5862V25.5932H42.0419V6.40503H46.2689V8.65636C47.62 7.02955 49.0052 6.40503 51.2565 6.40503C53.889 6.40503 55.5856 7.30692 56.901 9.38468C58.4597 7.30692 60.3639 6.40503 63.0662 6.40503C68.1219 6.40503 70.8241 9.2111 70.8241 14.9611V25.5932H66.2194Z" fill="#021221" />
            <path d="M91.2531 17.9454H77.1292C77.6567 20.1575 79.566 21.5342 82.1678 21.5342C83.9784 21.5342 85.0607 21.0407 86.1855 19.7236H90.9604C89.742 23.4775 85.9541 25.5519 82.2665 25.5519C76.702 25.5519 72.3883 21.4032 72.3883 16.0684C72.3788 14.8031 72.621 13.5486 73.1008 12.3779C73.5806 11.2071 74.2884 10.1434 75.1831 9.24871C76.0778 8.35402 77.1415 7.64617 78.3122 7.16637C79.483 6.68656 80.7375 6.44437 82.0028 6.45388C87.4362 6.45388 91.4216 10.6026 91.4216 16.2658C91.4321 16.8303 91.3756 17.3941 91.2531 17.9454ZM82.064 10.4699C79.4622 10.4699 77.6856 11.7546 76.993 14.0927H87.1027C86.5446 11.7546 84.7663 10.4699 82.0657 10.4699H82.064Z" fill="#021221" />
            <path d="M98.6373 25.4952C99.9183 25.4952 100.957 24.4568 100.957 23.1758C100.957 21.8949 99.9183 20.8564 98.6373 20.8564C97.3563 20.8564 96.3179 21.8949 96.3179 23.1758C96.3179 24.4568 97.3563 25.4952 98.6373 25.4952Z" fill="#A165FD" />
        </svg>
        <div>
            <span className="text-gray-600">Онлайн-подписание</span>
        </div>
    </header>
}