import {Outlet} from "react-router-dom"
import {Header} from "./header"
import {useEffect, useState} from "react"

const useViewportHeight = () => {
  const [vh, setVh] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
    }
  }, [])

  return vh
}

function App() {
  const vh = useViewportHeight()

  return (
    <div className="flex flex-col" style={{minHeight: vh, maxHeight: vh}}>
      <Header />
      <div className="pt-16">
        <Outlet />
      </div>
    </div>
  )
}

export default App
