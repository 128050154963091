import {useOutletContext} from "react-router-dom"
import axiosService from "../service"
import "./styles.css"

function DocumentSuccess() {
    const {document} = useOutletContext<{document: any}>()

    const onDownload = () => axiosService.download(document.content)

    return <div className="max-w-96 mx-auto flex flex-col items-center gap-6 my-12 lg:my-20">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#52C41A" />
            <path d="M21.3336 26.1332L29.2002 18.2666C29.4447 18.0221 29.7558 17.8999 30.1336 17.8999C30.5113 17.8999 30.8224 18.0221 31.0669 18.2666C31.3113 18.511 31.4336 18.8221 31.4336 19.1999C31.4336 19.5777 31.3113 19.8888 31.0669 20.1332L22.2669 28.9332C22.0002 29.1999 21.6891 29.3332 21.3336 29.3332C20.978 29.3332 20.6669 29.1999 20.4002 28.9332L16.9336 25.4666C16.6891 25.2221 16.5669 24.911 16.5669 24.5332C16.5669 24.1555 16.6891 23.8443 16.9336 23.5999C17.178 23.3555 17.4891 23.2332 17.8669 23.2332C18.2447 23.2332 18.5558 23.3555 18.8002 23.5999L21.3336 26.1332Z" fill="white" />
        </svg>
        <div className="flex flex-col gap-4 items-center text-[#021221]">
            <div className="text-[32px] font-bold">Документ подписан</div>
            <div className="text-[16px]">
                Спасибо за то, что пользуетесь нашим сервисом. Скачайте документ нажав на кнопку ниже.
            </div>
        </div>

        <button
            onClick={onDownload}
            className="flex gap-4 items-center justify-center bg-[#021221] text-white font-medium p-4 rounded-lg hover:bg-[#021221] w-full md:w-max"
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 15.575C11.8667 15.575 11.7417 15.5543 11.625 15.513C11.5083 15.4717 11.4 15.4007 11.3 15.3L7.7 11.7C7.5 11.5 7.404 11.2667 7.412 11C7.42 10.7333 7.516 10.5 7.7 10.3C7.9 10.1 8.13767 9.996 8.413 9.988C8.68833 9.98 8.92567 10.0757 9.125 10.275L11 12.15V5C11 4.71667 11.096 4.47934 11.288 4.288C11.48 4.09667 11.7173 4.00067 12 4C12.2827 3.99934 12.5203 4.09534 12.713 4.288C12.9057 4.48067 13.0013 4.718 13 5V12.15L14.875 10.275C15.075 10.075 15.3127 9.979 15.588 9.987C15.8633 9.995 16.1007 10.0993 16.3 10.3C16.4833 10.5 16.5793 10.7333 16.588 11C16.5967 11.2667 16.5007 11.5 16.3 11.7L12.7 15.3C12.6 15.4 12.4917 15.471 12.375 15.513C12.2583 15.555 12.1333 15.5757 12 15.575ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V16C4 15.7167 4.096 15.4793 4.288 15.288C4.48 15.0967 4.71733 15.0007 5 15C5.28267 14.9993 5.52033 15.0953 5.713 15.288C5.90567 15.4807 6.00133 15.718 6 16V18H18V16C18 15.7167 18.096 15.4793 18.288 15.288C18.48 15.0967 18.7173 15.0007 19 15C19.2827 14.9993 19.5203 15.0953 19.713 15.288C19.9057 15.4807 20.0013 15.718 20 16V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="white" />
            </svg>

            Скачать документ
        </button>

    </div>
}

export default DocumentSuccess