import ReactDOM from 'react-dom/client'
import {RouteObject, RouterProvider, createBrowserRouter} from 'react-router-dom'
import App from './App'
import DocumentWrapper from "./doc"
import Document from "./doc/document"
import DocumentSign from "./doc/confirm"
import DocumentSuccess from "./doc/success"
import './index.css'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

export const routes: RouteObject[] = [
  {
    path: '',
    element: <App />,
    children: [
      {
        path: ':code',
        element: <DocumentWrapper />,
        children: [
          {path: '', element: <Document />},
          {path: 'confirm', element: <DocumentSign />},
          {path: 'success', element: <DocumentSuccess />}
        ]
      }
    ]
  }
]

const router = createBrowserRouter(routes)

root.render(<RouterProvider router={router} />)
